import {Injectable} from "@angular/core";
import {FuseMockApiService, FuseMockApiUtils} from "../../../core/mock-api";
import {ApiResult} from "../../../shared/types/api-result.types";
import {MenuData} from "../../../features/main-screen/main.types";
import {ArticleNutritionalData} from "../../../features/article/article.types";
import {nutritionalData} from "./data";
import {environment} from "../../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class NutritionalMockApi {

  private _nutritional: ArticleNutritionalData[] = nutritionalData;

  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {

    // -----------------------------------------------------------------------------------------------------
    // @ Course - GET
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onGet(`${environment.apiUrl}/nutritional`, 2000)
      .reply(() => {
        const data = FuseMockApiUtils.cloneDeep(this._nutritional);

        const result: ApiResult<MenuData> = {
          result: data,
          isSuccess: true,
          errorMessages: [],
          statusCode: 200
        };

        return [200, result];

      });
  }
}
