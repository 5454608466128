import {ChangeDetectionStrategy, Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslocoDirective} from "@jsverse/transloco";

@Component({
  selector: 'app-modal-idle-timeout',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  template: `
    <ng-container *transloco="let t; read:'components.idle-modal'">
      <div class="font-heading">
        <div class="modal-body min-h-96 my-16 flex">
          <div class="flex flex-col text-3xl text-center text-gray-700 justify-center flex-grow gap-y-16">

            <div class="flex flex-col">
              <span class="font-bold text-5xl text-tb-primary" ngbAutofocus>{{ t('title') }}</span>
              <span>{{ t('desc') }}</span>
            </div>

            <div class="h-40">

            </div>

            <div class="flex flex-col mx-auto gap-y-8">
              <button class="rounded-tb px-8 py-8 bg-tb-primary text-white" (click)="activeModal.dismiss()">
                {{ t('btn-continue') }}
              </button>
              <button class="rounded-tb px-8 py-8 border" (click)="activeModal.close('reset')">
                {{ t('btn-reset') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  imports: [
    TranslocoDirective
  ]
})
export class ModalIdleTimeoutComponent {

  constructor(public activeModal: NgbActiveModal) {
  }

}
