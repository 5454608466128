import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {UpsellService} from "../upsell.service";

export const upsellBeforeCartActivateFn: CanActivateFn = () => {
  const upsellService = inject(UpsellService);
  const router = inject(Router);

  //we can go to the cart if we have shown the upsell before, otherwise go to upsell
  if (upsellService.shouldShowKioskUpsellData)
    router.navigate(
      ['upsell'],
      {queryParams: {parent: 'kiosk'}}
    ).then();

  return true;
};
