/**
 * Environment related to treebyte's development environment
 */
export const environment = {
  production: false,
  apiUrl: 'https://dev.api.stockstore.cloud/api/kiosk',
  useMockApi: false,
  applicationInsights: {
    connectionString: '',
    serviceName: 'kiosk-dev'
  }
};
