import {computed, inject, Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, map, Observable, of, shareReplay} from "rxjs";
import {BaseService} from "../../core/services/base.service";
import {ArticleTile, GroupTile, GroupTileChildTypes, MenuData} from "./main.types";
import {ApiResult} from "../../shared/types/api-result.types";
import {environment} from "../../../environments/environment";
import {CartService} from "../cart/cart.service";


@Injectable({
  providedIn: 'root'
})
export class TileDataService extends BaseService {

  http = inject(HttpClient);
  cartService = inject(CartService);

  mainSelectedGroup = signal<GroupTile | undefined>(undefined);
  selectedGroup = signal<GroupTile | undefined>(undefined);

  subGroupsTree: string[] = [];
  //`${environment.apiUrl}/${environment.apiVersion}/totem/main`
  // Mockup: api/menu
  // private data$: Observable<MenuData> = this.fetchMenuData();

  data = signal({} as MenuData);

  tagsMap = computed(() => {
    return new Map(this.data().tags.map(tag => [tag.code, tag]));
  })

  selectMainGroup(id: string) {
    const group = this.data().groups.find((v) => v.id === id)!;
    this.mainSelectedGroup.set(group);
    this.selectedGroup.set(group);
  }

  selectGroup(id: string) {
    const group = this.data().groups.find((v) => v.id === id)!;
    this.selectedGroup.set(group);
  }

  sideGroups = computed(() => {
    let groups: GroupTile[] = [];
    this.data().rootGroupsId.forEach(id => {
      let group = this.data().groups.find(g => g.id === id);
      if (group) {
        groups.push(group);
      }
    })
    return groups;
  });


  currentContent = computed(() => {
    const children = this.selectedGroup()?.children ?? [];

    let itemsWithoutTag: Array<GroupTile | ArticleTile> = [];
    let itemsByTag: { [key: string]: Array<GroupTile | ArticleTile> } = {};

    children.forEach(c => {

      const isGroup = c.type == GroupTileChildTypes.Group;
      const isArticle = c.type == GroupTileChildTypes.Article;

      let item: GroupTile | ArticleTile | undefined;

      if (isGroup) {
        item = this.data().groups.find(g => g.id === c.id);
      } else if (isArticle) {
        item = this.data().articles.find(a => a.id === c.id);
      }

      if (item) {
        const groupByTag = item.groupByTagCode ? this.tagsMap().get(item.groupByTagCode) : undefined;

        if (!groupByTag || !groupByTag.value) {
          itemsWithoutTag.push(item);
        } else {
          if (!itemsByTag[groupByTag.code]) {
            itemsByTag[groupByTag.code] = [];
          }
          itemsByTag[groupByTag.code].push(item);
        }
      }
    });

    // Combine items without tag and items with tag sorted by tag code
    const items: Array<GroupTile | ArticleTile> = [
      ...itemsWithoutTag,
      ...Object.keys(itemsByTag)
        .sort()
        .flatMap(tagCode => itemsByTag[tagCode])
    ];

    return items;
  });

  clear() {
    this.selectedGroup.set(undefined);
    this.mainSelectedGroup.set(undefined);
    this.subGroupsTree = [];
    this.data.set({} as MenuData);
  }

  fetchMenuData(): Observable<MenuData> {

    //Prevent refetching
    if (this.data()?.rootGroupsId?.length > 0)
      return of(this.data());

    return this.http.get<ApiResult<MenuData>>(`${environment.apiUrl}/main`, {
      params: {
        kioskId: this.kioskId,
        serviceType: this.cartService.cart().dinerMode
      }
    })
      .pipe(
        map(result => {
          if (!result.isSuccess) {
            console.log(result.errorMessages);
            of();
          }
          const data = result.result!;
          const group = data.groups.find(b => b.id == data.defaultGroupId);
          this.mainSelectedGroup.set(group);
          this.selectedGroup.set(group);
          this.cartService.setOrderStartTimestamp(data.orderStartTimestamp);
          return data;
        }),
        shareReplay(1),
        catchError(this.handleError)
      );
  }

}
