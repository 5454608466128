import {AppConfig} from "../../../core/config/config.types";

export const configData: AppConfig = {
  active: true,
  kioskCode: '',
  features: {
    idleTimeBeforeModal: 90,
    showSideGroupsGallery: true
  },
  paymentsSettings: {
    currencyCode: 'EUR',
    currencyId: 1,
    isNetVat: false,
    cash: {enabled: true},
    creditCard: {enabled: true}
  },
  serviceTypes: {
    takeAway: {enabled: true},
    tableService: {
      enabled: true,
      askForNumber: false,
      maxNumberDigits: 2
    }
  },
  languages: {
    defaultLanguage: 'it',
    languages: [
      {
        code: 'it'
      }
    ]
  },
  endpoints: {
    shopPos: 'http://10.18.101.157:1221/',
    kioskPos: 'http://10.18.101.157:1221/'
  },
  mediaSet: {}
}
