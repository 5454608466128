export interface AppConfig {
  /**
   * Used to identify a valid appconfig from an empty/undefined one.
   * API always returns true as of now.
   */
  active: boolean,
  kioskCode: string,
  features: {
    idleTimeBeforeModal: number;
    showSideGroupsGallery: boolean;
  },
  paymentsSettings: {
    currencyId: number;
    currencyCode: string;
    isNetVat: boolean;
    cash?: PaymentMethodConfig,
    creditCard?: PaymentMethodConfig
  },
  serviceTypes: {
    takeAway?: ServiceTypeConfig,
    tableService?: TableServiceConfig
  },
  languages: {
    defaultLanguage: string;
    languages: LanguageConfig[];
  },
  endpoints: {
    kioskPos: string;
    shopPos: string;
  },
  mediaSet: {
    welcomeMediaUrl?: string;
    welcomeMediaType?: number;
    mainTopBannerMediaUrl?: string;
    mainTopBannerMediaType?: number;
  }

}

export interface BaseConfig {
  enabled: boolean;
  mediaUrl?: string;
}

export interface LanguageConfig {
  code: string;
  mediaUrl?: string;
}

export interface ServiceTypeConfig extends BaseConfig {
}

export interface TableServiceConfig extends ServiceTypeConfig {
  askForNumber: boolean,
  maxNumberDigits: number
}


export interface PaymentMethodConfig extends BaseConfig {
}


export const emptyAppConfig: AppConfig = {
  active: false, //Keep false, to identify this as an empty/default config
  kioskCode: '',
  paymentsSettings: {
    currencyId: 1,
    currencyCode: 'EUR',
    isNetVat: false,
    cash: {enabled: false},
    creditCard: {enabled: false}
  },
  serviceTypes: {
    takeAway: {enabled: false},
    tableService: {
      enabled: false,
      maxNumberDigits: 2,
      askForNumber: false
    }
  },
  languages: {
    defaultLanguage: 'en',
    languages: [
      {
        code: 'en'
      }
    ]
  },
  features: {
    idleTimeBeforeModal: 90,
    showSideGroupsGallery: true
  },
  endpoints: {
    kioskPos: '',
    shopPos: ''
  },
  mediaSet: {}
}
