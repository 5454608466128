import {Injectable} from '@angular/core';
import {BaseService} from "./base.service";
import {CartService} from "../../features/cart/cart.service";
import {AppConfigService} from "../config/config.service";
import {TileDataService} from "../../features/main-screen/tile-data.service";
import {TranslocoService} from "@jsverse/transloco";
import {IdleService} from "../idle-timeout/idle.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UpsellService} from "../../features/upsell/upsell.service";

@Injectable({
  providedIn: 'root'
})
export class AppService extends BaseService {

  constructor(private _cart: CartService,
              private _conf: AppConfigService,
              private _tileService: TileDataService,
              private _transloco: TranslocoService,
              private _idleService: IdleService,
              private _modalService: NgbModal,
              private _upsellService: UpsellService) {
    super();
  }

  async resetApplication() {
    this._cart.clear();
    this._tileService.clear();
    this._idleService.deactivateIdleTimer();
    this._modalService.dismissAll();
    this._upsellService.reset();

    await this._conf.loadConfig().then(config => {
      this._transloco.setActiveLang(config.languages.defaultLanguage);
    });

  }


}
