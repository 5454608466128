import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslocoService} from "@jsverse/transloco";

@Injectable()
export class CultureInterceptor implements HttpInterceptor {

  constructor(private translocoService: TranslocoService) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    const newReq = req.clone({
      params: (req.params ? req.params : new HttpParams())
        .set('lang', this.translocoService.getActiveLang())
    });

    return next.handle(newReq);
  }


}
