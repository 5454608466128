import {Routes} from '@angular/router';
import {upsellBeforeCartActivateFn} from "./features/upsell/guards/upsell-kiosk.guard";

export const routes: Routes = [
  {
    path: '',
    children: [
      {path: '', loadChildren: () => import('src/app/features/welcome/welcome.routes').then(m => m.welcomeRoutes)},
      {
        path: 'configurator',
        loadChildren: () => import('src/app/features/configuration/configuration.routes').then(m => m.configurationRoutes)
      },
      {
        path: 'error',
        loadChildren: () => import('src/app/features/error/error.routes').then(m => m.errorRoutes)
      },
      {
        path: 'reset',
        loadChildren: () => import('src/app/features/reset-order-question/reset-order.routes').then(m => m.resetOrderRoutes)
      },
      {
        path: 'main',
        loadChildren: () => import('src/app/features/main-screen/main-screen.routes').then(m => m.mainRoutes)
      },
      {
        path: 'article',
        loadChildren: () => import('src/app/features/article/article.routes').then(m => m.articleRoutes)
      },
      {
        path: 'cart',
        loadChildren: () => import('src/app/features/cart/cart.routes').then(m => m.cartRoutes),
        canActivate: [upsellBeforeCartActivateFn]
      },
      {
        path: 'checkout',
        loadChildren: () => import('src/app/features/checkout/checkout.routes').then(m => m.checkoutRoutes)
      },
      {
        path: 'order-type',
        loadChildren: () => import('src/app/features/order-type-question/order-type-question.routes').then(m => m.orderTypeQuestionRoutes)
      },
      {
        path: 'code-reader',
        loadChildren: () => import('src/app/features/code-reader/code-reader.routes').then(m => m.codeReaderRoutes)
      },
      {
        path: 'choose-language',
        loadChildren: () => import('src/app/features/language/language-chooser.routes').then(m => m.languageChooserRoutes)
      },
      {
        path: 'upsell',
        loadChildren: () => import('src/app/features/upsell/upsell.routes').then(m => m.upsellRoutes)
      },
      {
        path: 'kiosk-blocked',
        loadChildren: () => import('src/app/features/kiosk-blocked/kiosk-blocked.routes').then(m => m.kioskBlockedRoutes)
      }
    ]
  },
];
