import {MenuMockApi} from "../../mock-api/features/main/api";
import {ConfigMockApi} from "../../mock-api/features/config/api";
import {CheckoutSSMockApi} from "src/app/mock-api/features/ss/api";
import {ArticleMockApi} from "../../mock-api/features/article/api";
import {NutritionalMockApi} from "../../mock-api/features/nutritional/api";

export * from './mock-api.constants';
export * from './mock-api.module';
export * from './mock-api.service';
export * from './mock-api.types';
export * from './mock-api.utils';

export const mockApiServices = [
  MenuMockApi,
  ArticleMockApi,
  CheckoutSSMockApi,
  ConfigMockApi,
  NutritionalMockApi
];

