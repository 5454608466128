/*
export interface TileConfig {
  id: string;
  position: "left" | "main" | "both";
  articleId?: string;
  categoryId?: string;
  styleClass?: string;
  content: Array<ImageTileContent | LabelTileContent>
}
*/

import {TagDto} from "../../shared/types/tag.types";

export interface TileContent {
  type: "image" | "text";
  styleClass?: string;
}


export interface ImageTileContent extends TileContent {
  type: "image";
  imageSrc: string;
  isBackground: boolean;
}

export interface LabelTileContent extends TileContent {
  type: "text";
  text: string;
}

////////////

export interface MenuData {
  defaultGroupId: string;
  rootGroupsId: string[];
  groups: GroupTile[],
  articles: ArticleTile[],
  tags: TagDto[],
  orderStartTimestamp?: string
}

export interface TileData {
  styleClass?: string;
  content: Array<ImageTileContent | LabelTileContent>
}

export const GroupTileChildTypes = {
  Article: 'article',
  Group: 'group'
}

export interface GroupTileChild {
  id: string,
  type: string
}

export interface GroupTile {
  id: string;
  type: string;
  name: string;
  children?: GroupTileChild[];
  groupByTagCode?: string;
  tile?: TileData;
}

export interface ArticleTile {
  id: string;
  type: string;
  name: string;
  referencePrice: number;
  discountedPrice?: number;
  discountedPriceDifference?: number;
  groupByTagCode?: string;
  tile: TileData;
  available: boolean;
}
