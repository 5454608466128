import {computed, Injectable, OnDestroy, signal} from '@angular/core';
import {ArticleCart, Cart, convertCartToSsCheckout, DinerMode, PaymentMethod} from "./cart.types";
import {BaseService} from "../../core/services/base.service";
import {catchError, Subject, throwError, timeout, TimeoutError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AppConfigService} from "../../core/config/config.service";
import {SS_CHECKOUT_ENDPOINT, SsResult, SsResultStatusCode} from "../stockstore-pos/stockstore-pos.types";
import {TranslocoService} from "@jsverse/transloco";
import cloneDeep from 'lodash-es/cloneDeep';

@Injectable({
  providedIn: 'root'
})
export class CartService extends BaseService implements OnDestroy {

  ssLocalEndpoint: string | undefined;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  config = AppConfigService.Config;

  constructor(private httpClient: HttpClient,
              private transloco: TranslocoService) {
    super();

    this.ssLocalEndpoint = AppConfigService.Config()?.endpoints?.kioskPos;

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  cart = signal<Cart>({items: [], dinerMode: 'restaurant', paymentMethod: 'cash'});

  itemsCount = computed(() => {
    return this.cart().items.reduce((a, b) => a + b.quantity, 0);
  });

  subTotal = computed(() => {
    let subtotal = 0;
    for (const article of this.cart().items) {
      subtotal += ArticleCart.totalPrice(article);
    }

    return subtotal;
  });

  setDinerMode(mode: DinerMode): void {
    this.cart.update(a => ({...a, dinerMode: mode}));
  }

  setPaymentMethod(method: PaymentMethod): void {
    this.cart.update(a => ({...a, paymentMethod: method}));
  }

  setOrderStartTimestamp(timestamp?: string): void {
    this.cart.update(a => ({...a, orderStartTimestamp: timestamp}));
  }

  addArticleToCart(newArticle: ArticleCart) {

    this.cart.update(a => {
      const newCart = {...a};
      newCart.items.push(cloneDeep(newArticle));
      return newCart;
    })

  }

  clear() {
    this.cart.set({items: [], dinerMode: "restaurant", paymentMethod: "cash"});
  }

  updateQuantity(index: number, increment: boolean) {
    const item = this.cart().items.at(index);
    if (item == undefined)
      return;

    if (increment)
      item.quantity += 1;
    else if (item.quantity > 1)
      item.quantity -= 1;

    this.cart.update(a => {
      return {...a};
    })

  }

  removeArticle(specifiedItem: ArticleCart) {

    //Remove the specified article and all related promotional items
    const itemsToRemove = [specifiedItem, ...this.findAssociatedArticles(specifiedItem)];

    this.cart().items = this.cart().items.filter(item => !itemsToRemove.includes(item));

    this.cart.update(a => {
      return {...a};
    })
  }

  updateArticleToCart(articleCart: ArticleCart, index: number) {
    this.cart().items[index] = cloneDeep(articleCart);

    this.cart.update(a => {
      return {...a};
    })
  }

  /**
   * Calls the calculate API of SS.
   */
  checkoutToSS() {
    const cart = this.cart();

    //Convert cart to dto for ss checkout
    const dto = convertCartToSsCheckout(cart, "calculate", this.config(), this.transloco.getActiveLang());


    return this.httpClient.post<SsResult<any>>(this.ssLocalEndpoint + SS_CHECKOUT_ENDPOINT, {
      ...dto
    })
      .pipe(
        timeout(300000), //30 sec
        catchError(error => {
          if (error instanceof TimeoutError) {
            error = {
              ...error,
              error: {
                statusCode: SsResultStatusCode.ERR_SS_TIMEOUT
              }
            };
          }
          return throwError(() => error);
        })
      );
  }

  /**
   * Returns a list of articles associated to the specified one. Eg: promotional upsells.
   * @param article
   */
  findAssociatedArticles(article: ArticleCart) {
    return this.cart().items.filter(a => a.upsell && a.upsell.parentCartEntryId === article.entryId);
  }

}
