import {AppConfig} from "../../core/config/config.types";

export interface Cart {
  items: ArticleCart[],
  dinerMode: DinerMode
  paymentMethod: PaymentMethod
  orderStartTimestamp?: string
}


export type DinerMode = "take_away" | "restaurant";
export type PaymentMethod = "cash" | "credit_card"


export interface CartSsCheckout {
  items: ArticleSs[],
  kioskCode: string,
  dinerMode: DinerMode,
  paymentMethod: PaymentMethod,
  currencyCode: string,
  isNetVat: boolean,
  checkoutMode: "calculate" | "finalize" | "suspend",
  languageCode: string
}

export interface UpsellDataCart {
  id: string;
  code: string;
  promotionalPrice?: number;
  parentCartEntryId?: string;
}

export class ArticleCart {
  //Cart entry id. Generated on creation.
  entryId: string;
  articleId: string;
  readableName: string;
  name: string;
  code: string;
  description: string;
  imageUrl?: string;
  vatValue: number;
  vatCode: string;
  quantity: number;
  referencePrice: number;
  bundleSurcharge?: number;
  articleSubType: "product" | "modifier" | "bundle" | "bundle_part" | "group_modifier" | "ingredients";
  subArticles: Map<string, ArticleCart>;
  upsell?: UpsellDataCart;

  constructor(params: {
    entryId?: string,
    articleId: string,
    readableName: string,
    name: string,
    code: string,
    description: string,
    imageUrl?: string,
    vatValue: number,
    vatCode: string,
    quantity: number,
    referencePrice: number,
    bundleSurcharge?: number;
    discountPrice?: number,
    articleSubType: "product" | "modifier" | "bundle" | "bundle_part" | "group_modifier" | "ingredients",
    subArticles?: Map<string, ArticleCart>
    upsell?: UpsellDataCart
  }) {

    this.entryId = !params.entryId ? crypto.randomUUID() : params.entryId;

    this.articleId = params.articleId;
    this.readableName = params.readableName;
    this.name = params.name;
    this.code = params.code;
    this.description = params.description;
    this.imageUrl = params.imageUrl;
    this.vatValue = params.vatValue;
    this.vatCode = params.vatCode;
    this.quantity = params.quantity;
    this.referencePrice = params.referencePrice;
    this.bundleSurcharge = params.bundleSurcharge;
    this.articleSubType = params.articleSubType;
    this.upsell = params.upsell;
    this.subArticles = params.subArticles || new Map<string, ArticleCart>();
  }

  get isPromotional(): boolean {
    //For the moment, an article is promotional if it has a price difference compared to reference price. In future this may change.
    return this.basePrice != this.referencePrice;
  }

  /**
   * Returns the base price of the article to use for calculations. It may depend on upsells, deals, and so on.
   */
  get basePrice(): number {
    return this.upsell?.promotionalPrice ?? this.referencePrice;
  }

  static create(): ArticleCart {
    return new ArticleCart({
      articleId: '',
      readableName: '',
      referencePrice: 0,
      quantity: 0,
      code: '',
      name: '',
      description: '',
      articleSubType: 'product',
      vatCode: '',
      vatValue: 0.0
    });
  }

  static totalPrice(article: ArticleCart): number {

    let price = article.basePrice;

    if (article.articleSubType == "product") {
      for (let group of article.subArticles.values())
        for (let mod of group.subArticles.values()) {
          price += (mod.basePrice * mod.quantity);
        }
    } else {
      //handling a bundle
      for (let section of article.subArticles.values())
        for (let article of section.subArticles.values()) {
          //Sum optional bundle surcharge to price.
          price += article?.bundleSurcharge ?? 0;
          for (let group of article.subArticles.values())
            for (let mod of group.subArticles.values()) {
              price += (mod.basePrice * mod.quantity);
            }
        }

    }

    return (price * article.quantity) / 100.0;
  }

  static getSelectedModifiers(article: ArticleCart): ArticleCart[] {
    return Array.from(article.subArticles.values())
      .flatMap(group => Array.from(group.subArticles.values()));
  }


}

export interface ArticleSs {
  name: string;
  readableName: string;
  code: string;
  vatPercent: number;
  vatCode: string;
  quantity: number;
  isCombo: boolean;
  price: number;
  bundleSurcharge?: number;
  subProducts: ArticleSs[];
}

export function convertCartToSsCheckout(cart: Cart, ssMode: "calculate" | "finalize" | "suspend", config: AppConfig, languageCode: string): CartSsCheckout {

  const dto: CartSsCheckout = {
    kioskCode: config.kioskCode,
    dinerMode: cart.dinerMode,
    items: [],
    paymentMethod: cart.paymentMethod,
    currencyCode: config.paymentsSettings.currencyCode,
    isNetVat: config.paymentsSettings.isNetVat,
    checkoutMode: ssMode,
    languageCode: languageCode
  };

  for (const item of cart.items) {
    const prodCheckout: ArticleSs = {
      code: item.code,
      vatPercent: item.vatValue,
      isCombo: item.articleSubType == "bundle",
      name: item.name,
      readableName: item.readableName,
      price: item.basePrice,
      quantity: item.quantity,
      vatCode: item.vatCode,
      subProducts: []
    }

    if (prodCheckout.isCombo) {
      for (const section of item.subArticles.values())
        for (const article of section.subArticles.values()) {
          const sectionProd: ArticleSs = {
            code: article.code,
            vatPercent: article.vatValue,
            vatCode: article.vatCode,
            name: article.name,
            isCombo: false,
            readableName: article.readableName,
            price: article.basePrice,
            bundleSurcharge: article?.bundleSurcharge,
            quantity: article.quantity,
            subProducts: []
          }

          for (const group of article.subArticles.values())
            for (const modifier of group.subArticles.values()) {
              const modCheckout: ArticleSs = {
                code: modifier.code,
                vatPercent: modifier.vatValue,
                vatCode: modifier.vatCode,
                name: modifier.name,
                isCombo: false,
                readableName: modifier.readableName,
                price: modifier.basePrice,
                quantity: modifier.quantity,
                subProducts: []
              }

              //Special case for ingredients: if group is ingredient, set quantity to negative.
              if (group.articleSubType == "ingredients")
                modCheckout.quantity *= -1;

              sectionProd.subProducts.push(modCheckout);
            }

          prodCheckout.subProducts.push(sectionProd);
        }
    } else {
      for (const group of item.subArticles.values())
        for (const modifier of group.subArticles.values()) {
          const modCheckout: ArticleSs = {
            code: modifier.code,
            vatPercent: modifier.vatValue,
            vatCode: modifier.vatCode,
            name: modifier.name,
            isCombo: false,
            readableName: modifier.readableName,
            price: modifier.basePrice,
            quantity: modifier.quantity,
            subProducts: []
          }

          //Special case for ingredients: if group is ingredient, set quantity to negative.
          if (group.articleSubType == "ingredients")
            modCheckout.quantity *= -1;

          prodCheckout.subProducts.push(modCheckout);
        }

    }

    dto.items.push(prodCheckout);
  }

  return dto;
}
