import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {getAccessibleColor, getCssVariable, getRGBColor} from "../utils/utils";
import {HttpClient} from "@angular/common/http";
import {ThemeConfig} from './theme.types';
import {BaseService} from "../services/base.service";
import {ApiResult} from "../../shared/types/api-result.types";
import {environment} from "../../../environments/environment";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends BaseService {

  private renderer2: Renderer2;

  constructor(private httpClient: HttpClient, rendererFactory: RendererFactory2) {
    super();
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }


  fetchTheme(): Promise<ThemeConfig> {
    return lastValueFrom(
      this.httpClient.get<ApiResult<ThemeConfig>>(`${environment.apiUrl}/theme`, {
        params: {kioskId: this.kioskId}
      })
    ).then(result => result?.result ?? {})
      .catch(error => {
        console.error(error);
        return {};
      });
  }

  loadTheme() {

    function importFont(headingFontUrl: string, headingFontFamily: string, bodyFontUrl: string, bodyFontFamily: string) {
      const fontStyle = `
       @import url('${headingFontUrl}');
      @import url('${bodyFontUrl}');

      @font-face {
      font-family: '${headingFontFamily}';
      src: url('${headingFontUrl}') format('truetype');
    }
    @font-face {
      font-family: '${bodyFontFamily}';
      src: url('${bodyFontUrl}') format('truetype');
    }

      `;

      const node = document.createElement('style');
      node.innerHTML = fontStyle;
      document.body.appendChild(node);

    }

    this.fetchTheme().then(data => {

      let primary = data?.colors?.primary ?? "#055bbd";
      let secondary = data?.colors?.secondary ?? "#c4a00a"
      let borderRadius = data?.borderRadius ?? "0px";
      let headingFont = data?.fonts?.heading?.url ?? "";
      let bodyFont = data?.fonts?.body?.url ?? "";
      const headingFontFamily = data?.fonts?.heading?.family!;
      const bodyFontFamily = data?.fonts?.body?.family!;

      importFont(headingFont, headingFontFamily, bodyFont, bodyFontFamily);

      const primaryColor = getRGBColor(primary, "primary");
      const secondaryColor = getRGBColor(secondary, "secondary");

      const primaryReadable = getRGBColor(getAccessibleColor(primary), "primary-read");
      const secondaryReadable = getRGBColor(getAccessibleColor(secondary), "secondary-read");

      const borderRadiusVariable = getCssVariable(borderRadius, "border-radius");
      const headingFontVariable = getCssVariable(headingFontFamily + ", sans-serif", "heading-font");
      const bodyFontVariable = getCssVariable(bodyFontFamily + ", sans-serif", "body-font");

      let concat: string;
      concat = primaryColor + primaryReadable + secondaryColor + secondaryReadable + borderRadiusVariable +
        headingFontVariable + bodyFontVariable;

      document.styleSheets[0].insertRule(`:root{${concat}}`);

      this.importCss(data.cssContent);
    });

  }

  private importCss(cssContent?: string) {

    if (!cssContent)
      return;

    // Create a style element via Angular renderer
    const style = this.renderer2.createElement('style') as HTMLStyleElement;

    // Add the style content
    style.innerHTML = cssContent;

    // Append the style to the head section
    this.renderer2.appendChild(document.head, style);

  }


}
