import {Injectable} from "@angular/core";
import {FuseMockApiService} from "../../../core/mock-api";
import {
  SS_CHECKOUT_ENDPOINT,
  SS_PRINT_ENDPOINT,
  SsResult,
  SsResultStatusCode
} from "../../../features/stockstore-pos/stockstore-pos.types";


@Injectable({
  providedIn: 'root'
})
export class CheckoutSSMockApi {

  private readonly okResult: SsResult<any> = {
    isSuccess: true,
    errorMessages: [],
    statusCode: SsResultStatusCode.OK,
    result: "ok"
  };

  private readonly failResult: SsResult<any> = {
    isSuccess: false,
    errorMessages: [],
    statusCode: SsResultStatusCode.ERR_PRINTER_KO,
    result: "ok"
  };

  constructor(private _fuseMockApiService: FuseMockApiService) {
    this.registerHandlers();
  }

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {

    // -----------------------------------------------------------------------------------------------------
    // @ Checkout POST: Handles mock of SS checkout API
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost("http://127.0.0.1:1221/" + SS_CHECKOUT_ENDPOINT, 1000)
      .reply((request) => {

        // Mock specific behaviour for calculate mode.
        if (request.request.body.checkoutMode == "calculate") {
          return [200,
            this.okResult
          ]
        }

        return [
          200,
          this.okResult
        ];
      });

    // -----------------------------------------------------------------------------------------------------
    // @ PRINT POST: Handles mock of SS print API
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost("http://127.0.0.1:1221/" + SS_PRINT_ENDPOINT, 1000)
      .reply(() => {

        return [
          200,
          this.okResult
        ];
      });


  }
}
