import {computed, Injectable, Injector} from '@angular/core';
import {ModalIdleTimeoutComponent} from "./components/modal-idle-timeout.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AppService} from "../services/app.service";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {AppConfigService} from '../config/config.service';

/**
 * Service used to handle idle and timeout functionality in the whole application.
 */
@Injectable({
  providedIn: 'root'
})
export class IdleService {

  /*
  * Duration in ms with no actions performed to wait until we consider the user idling (open modal to verify his presence)
  */
  idlePeriod = computed(() => {
    return (AppConfigService.Config()?.features?.idleTimeBeforeModal ?? 90) * 1000 //seconds to milliseconds;
  });

  /*
  * Duration in ms before triggering an user timeout (kiosk reset)
   */
  private readonly timeOutPeriod = 30 * 1000; //30 seconds

  activateIdleTimer() {
    this.idleTimerActive.next(true);
  }

  deactivateIdleTimer() {
    this.idleTimerActive.next(false);
  }

  private idleTimerActive = new BehaviorSubject<boolean>(false);

  private idleActionId: any;
  private timeoutActionId: any;

  constructor(private _modalService: NgbModal,
              private injector: Injector,
              private _router: Router) {

    this.idleTimerActive
      .subscribe((active: boolean) => {
        if (active) {
          this.startIdleTimer();
        } else {
          this.clearIdleTimer();
        }
      });

  }

  resetIdleTimer(): void {
    this.clearIdleTimer();

    //Important: without this check, we would enable the timer after any action, because the input check triggers this method.
    //Re-start the timer only if active
    if (this.idleTimerActive.value)
      this.startIdleTimer();
  }

  private clearIdleTimer(): void {
    if (this.idleActionId)
      clearTimeout(this.idleActionId);
  }

  private clearTimeoutTimer(): void {
    if (this.timeoutActionId)
      clearTimeout(this.timeoutActionId);
  }

  /**
   * Opens the idle modal asking the user if they're still here. If no input is provided or the specific button is pressed, the application will be reset.
   *
   * Notes:
   * - Do not close modals by using the appropriate service inside this method before opening this one, otherwise we would possibly close other valid modals in the app
   * - timeout period should be much inferior to the idle one, so we should not worry about checking if we've already opened a similar modal. Even so, nothing wrong would happen.
   */
  openIdleModal() {
    this.clearIdleTimer();

    const modalRef = this._modalService.open(ModalIdleTimeoutComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static'
    });

    // Start the timeout timer when the modal is opened
    this.timeoutActionId = setTimeout(() => {
      if (modalRef)
        modalRef.close('reset');
    }, this.timeOutPeriod);

    modalRef.result.then(
      (result) => {
        // Clear the timeout timer regardless of the outcome
        this.clearTimeoutTimer();

        //Reset order
        if (result == 'reset') {
          this.resetApplication();
        } else {
          //close modal and proceed
          this.resetIdleTimer();
        }

      },
      () => {
      });
  }

  private resetApplication(): void {
    //Inject here otherwise we'll get a circular dependency
    const appService = this.injector.get(AppService);

    appService.resetApplication().then(() => {
      this._router.navigate(['/']);
    });
  }

  private startIdleTimer(): void {
    this.idleActionId = setTimeout(() => this.openIdleModal(), this.idlePeriod());
  }
}
