import {Injectable} from "@angular/core";
import {FuseMockApiService, FuseMockApiUtils} from "../../../core/mock-api";
import {ArticleData} from "../../../features/article/article.types";
import {ArticlesData} from "./data";
import {ApiResult} from '../../../shared/types/api-result.types';
import {environment} from "../../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ArticleMockApi {
  private _articles: ArticleData = ArticlesData;

  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {

    // -----------------------------------------------------------------------------------------------------
    // @ Course - GET
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onGet(`${environment.apiUrl}/article`)
      .reply(() => {
        // Simply return all articles instead of filtering by ids
        const articles: ArticleData = FuseMockApiUtils.cloneDeep(this._articles);

        const result: ApiResult<ArticleData> = {
          result: articles,
          isSuccess: true,
          errorMessages: [],
          statusCode: 200
        };

        return [200, result];
      });


  }
}
