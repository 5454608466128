import {Injectable} from '@angular/core';
import {UpsellData} from "./upsell.types";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseService} from "../../core/services/base.service";
import {catchError, EMPTY, Observable, tap} from "rxjs";
import {ApiResult} from "../../shared/types/api-result.types";
import {environment} from "../../../environments/environment";
import {CartService} from "../cart/cart.service";

@Injectable({
  providedIn: 'root'
})
export class UpsellService extends BaseService {

  /**
   * Kiosk upsell should only be displayed once: when entering the cart for the first time.
   */
  private hasShownKioskUpsell = false;
  private hasFetchedKioskUpsell = false;
  kioskUpsellData?: UpsellData | null | undefined = undefined;

  /**
   * Map containing the upselldata associated to the specific articles (id as key).
   * This should be populated for a specific article the first time we enter that article page.
   * a Null value indicates that we already fetched the data, but there is none associated.
   */
  protected articlesUpsellDataMap = new Map<string, UpsellData | null>();

  get shouldShowKioskUpsellData() {
    return !this.hasShownKioskUpsell && ((this.kioskUpsellData?.articles?.length ?? 0) > 0);
  }

  shouldShowArticleUpsellData(articleId: string): boolean {
    return this.articlesUpsellDataMap.get(articleId) != null;  //valid both for null and undefined
  }

  constructor(private httpClient: HttpClient, private _cartService: CartService) {
    super();
  }


  markKioskUpsellAsShown() {
    this.hasShownKioskUpsell = true;
  }

  fetchKioskUpsellData(): Observable<ApiResult<UpsellData>> {

    //Check first if data already exists
    if (this.hasFetchedKioskUpsell) {
      return EMPTY;
    }

    return this.httpClient.get<ApiResult<UpsellData>>(`${environment.apiUrl}/upsell`,
      {
        params: {
          kioskId: this.kioskId,
          serviceType: this._cartService.cart().dinerMode
        }
      })
      .pipe(catchError(this.handleError),
        tap(result => {

          if (result.isSuccess) {
            this.kioskUpsellData = result?.result;
            //Mark data as fetched
            this.hasFetchedKioskUpsell = true;
          }
        })
      );
  }

  fetchArticleUpsellData(articleId: string) {

    //Check map first if data already exists
    if (this.articlesUpsellDataMap.has(articleId)) {
      return EMPTY;
    }

    let params: HttpParams = new HttpParams();
    params = params.appendAll({
      articleId: articleId,
      kioskId: this.kioskId,
      serviceType: this._cartService.cart().dinerMode
    });

    if (this._cartService.cart().orderStartTimestamp)
      params = params.append('orderStartTimestamp', this._cartService.cart().orderStartTimestamp!);

    return this.httpClient.get<ApiResult<UpsellData>>(`${environment.apiUrl}/upsell`,
      {
        params: params
      })
      .pipe(catchError(this.handleError),
        tap(result => {

          if (result.isSuccess)
            this.articlesUpsellDataMap.set(articleId, result?.result ?? null);
          else
            this.articlesUpsellDataMap.set(articleId, null);
        })
      );
  }

  getArticleUpsellData(articleId: string) {
    return this.articlesUpsellDataMap.get(articleId);
  }

  /**
   * Resets all data related to upsells.
   */
  reset() {
    this.hasShownKioskUpsell = false;
    this.hasFetchedKioskUpsell = false;
    this.kioskUpsellData = undefined;
    this.articlesUpsellDataMap.clear();
  }

}
