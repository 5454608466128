import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError, timer} from 'rxjs';
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {
  SS_CHECKOUT_ENDPOINT,
  SS_POS_ACTION,
  SS_PRINT_ENDPOINT
} from "../../features/stockstore-pos/stockstore-pos.types";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  /**
   * List of application paths where this interceptor should not operate.
   * Note: Allow us to go in the configurator page, otherwise we would not be able to correct wrong data.
   * As the application loads the configuration when being created and for example a 404 can be returned.
   * @private
   */
  private readonly excludedPaths = ['/configurator'];
  /**
   * List of api endpoints where this interceptor should not operate
   * @private
   */
  private readonly excludedUrls = [SS_CHECKOUT_ENDPOINT, SS_PRINT_ENDPOINT, SS_POS_ACTION];

  constructor(private router: Router, private location: Location) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      //retry({count: 2, delay: this.shouldRetry}),
      catchError(error => {

        const isExcludedEndpoint = this.excludedUrls.some(url => request.url.includes(url));
        const isExcludedPath = this.excludedPaths.includes(this.location.path());


        if (!isExcludedPath && !isExcludedEndpoint)
          return this.handleResponseError(error);
        else
          return throwError(() => error);
      })
    );
  }

  shouldRetry(error: HttpErrorResponse) {
    if (error.status == 0) {
      return timer(2000);
    }
    throw error;
  }

  private handleResponseError(error: any) {

    if (error.status == 0)
      this.router.navigate(['error'], {queryParams: {cause: 'network'}, replaceUrl: true}).then();
    else
      this.router.navigate(['error'], {queryParams: {cause: error.status}, replaceUrl: true}).then();

    return throwError(() => error);
  }
}
