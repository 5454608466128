import {MenuData} from "../../../features/main-screen/main.types";


export const menuData: MenuData = {
  defaultGroupId: "promo",
  rootGroupsId: ["caffetteria", "pasticceria", "bevande", "salato"],
  groups: [
    {
      id: 'caffetteria',
      type: 'group',
      name: 'Caffetteria',
      children: [{id: 'PANCAF0036', type: 'article'}, {id: 'PANCAF0004', type: 'article'}],
      tile: {
        content: [
          {
            type: "text",
            text: "Caffetteria",
            styleClass: 'label-name-tile'
          }
        ]
      }
    },
    {
      id: 'pasticceria',
      type: 'group',
      name: 'Pasticceria',
      children: [{id: 'PANFOO0102', type: 'article'}, {id: 'PANFOO0102', type: 'article'}],
      tile: {
        content: [
          {
            type: "text",
            text: "Pasticceria",
            styleClass: 'label-name-tile'
          }
        ]
      }
    },
    {
      id: 'bevande',
      type: 'group',
      name: 'Bevande',
      children: [{id: 'PANBEV0039', type: 'article'}, {id: 'PANBEV0038', type: 'article'}],
      tile: {
        content: [
          {
            type: "text",
            text: "Bevande",
            styleClass: 'label-name-tile'
          }
        ]
      }
    },
    {
      id: 'salato',
      type: 'group',
      name: 'Salato',
      children: [{id: 'PANFOO0244', type: 'article'}, {id: 'PANFOO0037', type: 'article'}],
      tile: {
        content: [
          {
            type: "text",
            text: "Salato",
            styleClass: 'label-name-tile'
          }
        ]
      }
    },
  ],
  articles: [
    {
      id: 'PANCAF0036',
      type: 'article',
      name: 'Caffe',
      referencePrice: 130,
      tile: {
        content: [
          {
            type: "text",
            text: "Caffe",
            styleClass: 'label-name-tile'
          },
          {
            type: "text",
            text: "1,30",
            styleClass: 'label-price-tile'
          }
        ]
      },
      available: true
    },
    {
      id: 'PANCAF0004',
      type: 'product',
      name: 'Caffe decaffeinato',
      referencePrice: 130,
      tile: {
        content: [
          {
            type: "text",
            text: "Caffe decaffeinato",
            styleClass: 'label-name-tile'
          },
          {
            type: "text",
            text: "1,30",
            styleClass: 'label-price-tile'
          }
        ]
      },
      available: true
    },
    {
      id: 'PANFOO0102',
      type: 'product',
      name: 'Ciambella',
      referencePrice: 190,
      tile: {
        content: [
          {
            type: "text",
            text: "Ciambella",
            styleClass: 'label-name-tile'
          },
          {
            type: "text",
            text: "1,90",
            styleClass: 'label-price-tile'
          }
        ]
      },
      available: true
    },
    {
      id: 'PANFOO0281',
      type: 'product',
      name: 'Croissant farcito',
      referencePrice: 150,
      tile: {
        content: [
          {
            type: "text",
            text: "Croissant farcito",
            styleClass: 'label-name-tile'
          },
          {
            type: "text",
            text: "1,50",
            styleClass: 'label-price-tile'
          }
        ]
      },
      available: true
    },
    {
      id: 'PANBEV0039',
      type: 'product',
      name: 'Coca cola',
      referencePrice: 350,
      tile: {
        content: [
          {
            type: "text",
            text: "Coca cola",
            styleClass: 'label-name-tile'
          },
          {
            type: "text",
            text: "3,50",
            styleClass: 'label-price-tile'
          }
        ]
      },
      available: true
    },
    {
      id: 'PANBEV0038',
      type: 'product',
      name: 'Fanta',
      referencePrice: 350,
      tile: {
        content: [
          {
            type: "text",
            text: "Fanta",
            styleClass: 'label-name-tile'
          },
          {
            type: "text",
            text: "3,50",
            styleClass: 'label-price-tile'
          }
        ]
      },
      available: true
    },
    {
      id: 'PANFOO0244',
      type: 'product',
      name: 'Tortino ricotta e spinaci',
      referencePrice: 290,
      tile: {
        content: [
          {
            type: "text",
            text: "Tortino ricotta e spinaci",
            styleClass: 'label-name-tile'
          },
          {
            type: "text",
            text: "2,90",
            styleClass: 'label-price-tile'
          }
        ]
      },
      available: true
    },
    {
      id: 'PANFOO0037',
      type: 'product',
      name: 'Patatine fritte',
      referencePrice: 290,
      tile: {
        content: [
          {
            type: "text",
            text: "Patatine fritte",
            styleClass: 'label-name-tile'
          },
          {
            type: "text",
            text: "3,00",
            styleClass: 'label-price-tile'
          }
        ],
      },
      available: true
    }


  ],
  tags: []
}
