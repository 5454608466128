/**
 * List of SS APIs.
 * Note: remember to add them to ErrorHandlerInterceptor if applicable.
 */
export const SS_CHECKOUT_ENDPOINT = "api/checkout";
export const SS_PRINT_ENDPOINT = "api/print";
export const SS_POS_ACTION = "api/posaction";


/**
 * Result type returned by StockStore apis
 */
export interface SsResult<T> {
  statusCode: string;
  errorMessages: string[];
  isSuccess: boolean;
  result: T
}

/**
 * StatusCode enum used by SsResult
 */
export abstract class SsResultStatusCode {
  /**
   * Error value not actually returned. Used for client side errors (network related, generic, etc.)
   */
  static readonly ERR_CLIENT = "ERR_CLIENT";
  /**
   * Returned in case of success
   */
  static readonly OK = "OK";
  /**
   Returned in case of success, but with the end of paper flag.
   */
  static readonly OK_EOP = "OK_EOP";
  /**
   * Returned in case of TM error
   */
  static readonly ERR_PRINTER_KO = "ERR_PRINTER_KO";
  /**
   * Returned in case of transaction abort by the customer
   */
  static readonly ERR_ABORT = "ERR_ABORT";
  /**
   * Returned in case of generic payload error
   */
  static readonly ERR_PAYLOAD = "ERR_PAYLOAD";
  /**
   * Returned in case of POS error
   */
  static readonly ERR_POS_KO = "ERR_POS_KO";
  /**
   * Returned in case of POS timeout
   */
  static readonly ERR_POS_TIMEOUT = "ERR_POS_TIMEOUT";
  /**
   * Used in case of SS timeout
   */
  static readonly ERR_SS_TIMEOUT = "ERR_SS_TIMEOUT";
  /**
   * Used in case of cart error during calculate. This could happen when there is inconsistency between SS and Kiosk.
   */
  static readonly ERR_SS_CART_INVALID = "ERR_SS_CART_INVALID";

}


/**
 * Body used by the 'posaction' api
 */
export interface PosActionApiBody {
  action: "show" | "hide" | "quit_kiosk" | "quit";
}
