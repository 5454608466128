import {Injectable} from "@angular/core";
import {FuseMockApiService, FuseMockApiUtils} from "../../../core/mock-api";
import {configData} from "./data";
import {MenuData} from "../../../features/main-screen/main.types";
import {ApiResult} from "../../../shared/types/api-result.types";
import {AppConfig} from "../../../core/config/config.types";
import {environment} from "../../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ConfigMockApi {
  private _config: AppConfig = configData;

  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Tiles - GET
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onGet(`${environment.apiUrl}/config`)
      .reply(() => {

        const data = FuseMockApiUtils.cloneDeep(this._config);

        const result: ApiResult<MenuData> = {
          result: data,
          isSuccess: true,
          errorMessages: [],
          statusCode: 200
        };

        return [200, result];
      });

  }
}
