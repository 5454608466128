import {Component, ErrorHandler, HostListener, OnInit} from '@angular/core';
import {ThemeService} from "./core/theme/theme.service";
import {RouterOutlet} from '@angular/router';
import {IdleService} from './core/idle-timeout/idle.service';

/*
    !!! DO NOT REMOVE THE IMPORT BELOW !!!
    In order to track uncaught exceptions using ErrorService, it is required to import the specified extension
    https://learn.microsoft.com/it-it/azure/azure-monitor/app/javascript-framework-extensions?tabs=angular#track-exceptions
*/
import '@microsoft/applicationinsights-analytics-js';
import {
  ApplicationinsightsAngularpluginErrorService
} from '@microsoft/applicationinsights-angularplugin-js';
import {MonitoringService} from "./core/services/monitoring.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [RouterOutlet],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
})
export class AppComponent implements OnInit {

  constructor(private themeService: ThemeService,
              private idleService: IdleService,
              private monitoringService: MonitoringService) {
  }

  ngOnInit(): void {
    this.themeService.loadTheme();
  }

  /**
   * Listens for the specified events in any part of the application, and resets the idle timer (if currently active).
   */
  @HostListener('window:click', ['$event'])
  @HostListener('window:keypress', ['$event'])
  @HostListener('window:touchstart', ['$event'])
  resetIdleTimer() {
    this.idleService.resetIdleTimer();
  }


}

