import {Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AppConfig, emptyAppConfig} from "./config.types";
import {ApiResult} from "../../shared/types/api-result.types";
import {environment} from "../../../environments/environment";
import {BaseService} from "../services/base.service";
import {KioskStorageConfiguration} from "../../features/configuration/configuration.types";


@Injectable({
  providedIn: 'root'
})
export class AppConfigService extends BaseService {

  static Config = signal<AppConfig>(emptyAppConfig);

  constructor(private httpClient: HttpClient) {
    super();
  }

  loadConfig(): Promise<AppConfig> {

    if (!this.kioskId)
      console.log("***NO KIOSK ID ASSIGNED. ASSIGN USING CONFIGURATOR***");

    return new Promise<AppConfig>(resolve => {
      // mockApi: api/config
      // real: `${environment.apiUrl}/config`
      this.httpClient.get<ApiResult<AppConfig>>(`${environment.apiUrl}/config`, {
        params: {
          kioskId: this.kioskId,
        }
      })
        .subscribe({
          next: (result) => {
            const data = result.result!;
            AppConfigService.Config.set(data);
            resolve(data);
          },

          error: (e) => {
            console.error(e);
            AppConfigService.Config.set(emptyAppConfig);
            resolve(emptyAppConfig);
          }
        });

    });
  }


  /**
   * Returns true if the specified code matches the previously stored security pin (or the default if none), false otherwise
   * @param code
   */
  verifySecurityPin(code: string): boolean {
    let securityPin = localStorage.getItem(KioskStorageConfiguration.SECURITY_PIN);

    //Use 0000 as default value if securityPin was not specified
    if (securityPin)
      return code === securityPin;
    else
      return code === "0000";
  }

}
