import {ArticleNutritionalData} from "../../../features/article/article.types";


export const nutritionalData: ArticleNutritionalData[] = [
  {
    allergens: [2, 5],
    articleId: "1",
    publicName: 'Gelato 1',
    longDescription: "Il gelato più buono del mondo",
    imageUrl: "https://storage.de.cloud.ovh.net/v1/AUTH_709726ad778840adbb0597a549c834f5/treebyte/kiosk/Cono_mini.png",
    foodTypes: [1],
    ingredients: "latte, cacao, panna",
    nutritionalValues: {
      kcal: 50
    }
  },
  {
    allergens: [6, 7],
    articleId: "2",
    publicName: 'Biscotti',
    imageUrl: "https://storage.de.cloud.ovh.net/v1/AUTH_709726ad778840adbb0597a549c834f5/treebyte/kiosk/biscotti2.png",
    foodTypes: [1],
    ingredients: "latte, cacao, panna",
    nutritionalValues: {
      kcal: 50
    }
  },
  {
    allergens: [],
    articleId: "3",
    publicName: 'Prodotto bello 1',
    foodTypes: [1],
    ingredients: "latte, cacao, panna",
    nutritionalValues: {
      kcal: 50
    }
  },
  {
    allergens: [],
    foodTypes: [],
    articleId: "4",
    publicName: 'Prodotto bello 2',
    nutritionalValues: {
      kcal: 50,
      fats: 20,
      sugars: 24.5,
      proteins: 11.3
    }
  }
];
