/// <reference types="@angular/localize" />

import {AppComponent} from './app/app.component';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {routes} from './app/app.routes';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {AppConfigService} from './app/core/config/config.service';
import {APP_INITIALIZER, importProvidersFrom, isDevMode, LOCALE_ID} from '@angular/core';
import {provideRouter} from "@angular/router";
import {ThemeService} from "./app/core/theme/theme.service";
import {FuseMockApiModule, mockApiServices} from "./app/core/mock-api";
import {provideAnimations} from "@angular/platform-browser/animations";
import {ErrorHandlerInterceptor} from "./app/core/interceptors/error-handler.interceptor";
import {TranslocoHttpLoader} from './transloco-loader';
import {provideTransloco, TranslocoService} from '@jsverse/transloco';
import {CultureInterceptor} from "./app/core/interceptors/culture.interceptor";
import localeIt from '@angular/common/locales/it';
import {registerLocaleData} from "@angular/common";
import {KioskSupportedLanguages} from './app/features/language/language.types';


registerLocaleData(localeIt);

export function setupAppConfigServiceFactory(service: AppConfigService, translocoService: TranslocoService) {
  return () => {
    service.loadConfig().then(config => {
      translocoService.setActiveLang(config.languages.defaultLanguage);
    });
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    {
      provide: APP_INITIALIZER,
      useFactory: setupAppConfigServiceFactory,
      deps: [
        AppConfigService,
        TranslocoService
      ],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CultureInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT'
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    {provide: ThemeService, useClass: ThemeService},
    provideAnimations(),
    importProvidersFrom(FuseMockApiModule.forRoot(mockApiServices)), provideHttpClient(), provideTransloco({
      config: {
        availableLangs: KioskSupportedLanguages.map(a => a.code),
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader
    })
  ]
})
  .catch(err => console.error(err));
